import SelectPlanModalContentC from 'components/pricing/tests/abc-modal_sales_payment_2_2_4/pricingTestC';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { userSubscriptionSelector } from 'data/selectors/user';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ModalContainer } from '../baseModal/styles';
import { Container } from './styles';

const StyledModalContainer = styled(Container)``;

const SelectPlanModalC = () => {
  const dispatch = useDispatch();
  useCloseModalOnBack();
  const userSubscription = useSelector(userSubscriptionSelector);

  const handleCloseModal = useCallback(() => {
    dispatch(sendAnalyticEvent({ event: 'select_plan_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  }, [dispatch]);

  useEffect(() => {
    if (userSubscription) {
      dispatch(toggleModal({ visible: false }));
    }
  }, [dispatch, userSubscription]);

  return (
    <ModalContainer onClick={() => handleCloseModal()}>
      <StyledModalContainer onClick={(e) => e.stopPropagation()}>
        <SelectPlanModalContentC />
      </StyledModalContainer>
    </ModalContainer>
  );
};

export default SelectPlanModalC;
