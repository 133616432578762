import { isShowUSAAddressSelector } from 'data/selectors/user';
import { useSelector } from 'react-redux';

export const useLegalAddress = (): {
  legalName: string;
  legalAddress: string;
  legalCountry: string;
} => {
  // temporarily for the bank's review for all USA
  const isUSAUser = useSelector(isShowUSAAddressSelector);

  if (isUSAUser)
    return {
      legalName: 'GenDoc Solutions Limited',
      legalAddress: '400 S. 4th Street, 3rd Floor, Las Vegas, Nevada NV 89101, USA',
      legalCountry: 'United States',
  }; else
    return {
      legalName: 'XAFENIL LIMITED',
      legalAddress:
        'Petraki Giallourou 1, Tofaris Gardens, 1st floor, Office/Flat 101, Latsia, 2220 Nicosia, Cyprus',
      legalCountry: 'Republic Cyprus',
    };
};
