export const countMonthlyPriceForAnnual = (fullPrice: number | undefined) => {
  if (!fullPrice) {
    return 0;
  }

  const monthRaw = fullPrice / 12;

  if ((monthRaw / 100) % 1 === 0) {
    return monthRaw;
  }

  return Math.ceil(monthRaw / 100) * 100;
};
