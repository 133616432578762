import { createSelector } from 'reselect';

const baseSubscriptionsSelector = (state: any) => state?.subscriptions;

export const selectedPlanSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.selectedPlan
);

export const plansSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plans || []
);

export const plansTestBSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansTestB || []
);

export const plansRecurrentSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansRecurrent || []
);

export const aiPlansRecurrentSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansRecurrentAI || []
);

export const latAmPlansSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansLatAm || []
);

export const aiLatAmPlansSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansLatAmAI || []
);

export const plansJapaneseSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansJapanese || []
);

export const aiPlansJapaneseSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansJapaneseAI || []
);

export const plansPolishTurkeySelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansPolishTurkey || []
);

export const aiPlansPolishTurkeySelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansPolishTurkeyAI || []
);

export const orderSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.order || {}
);

export const subscriptionOfferProductIdSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.offerPlan?.id || 'f0f065f4-a2a9-4afa-b754-443998d9f58d'
);

export const subscriptionOfferTestVProductIdSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.offerPlanTestB?.id || 'd4404dcb-9310-4ea4-a29f-569262a5a212'
);

export const subscriptionOfferSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.offerPlan || {}
);
