import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import { EServiceType } from 'ts/interfaces/services/service';

export const mergePageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: '/merge-pdf',
    name: t('service_page.merge_pdf.name'),
    nameKey: 'service_page.merge_pdf.name',
    title: t('service_page.merge_pdf.title'),
    aiTitle: t('service_page.merge_pdf.ai_title'),
    description: t('service_page.merge_pdf.description'),
    heroFeatures: [
      {
        title: t('service_page.merge_pdf.heroFeatures.0.title'),
        description: t('service_page.merge_pdf.heroFeatures.0.description'),
      },
      {
        title: t('service_page.merge_pdf.heroFeatures.1.title'),
        description: t('service_page.merge_pdf.heroFeatures.1.description'),
      },
      {
        title: t('service_page.merge_pdf.heroFeatures.2.title'),
        description: t('service_page.merge_pdf.heroFeatures.2.description'),
      },
    ],
    icon: Pdf2WordIcon,
    from: 'PDF',
    to: 'DOCX',
    availableFormats: '.pdf',
    aboutSection: {
      title: t('service_page.merge_pdf.aboutSection.title'),
      items: [
        t('service_page.merge_pdf.aboutSection.items.0'),
        t('service_page.merge_pdf.aboutSection.items.1'),
        t('service_page.merge_pdf.aboutSection.items.2'),
      ],
    },
    ctaTitle: t('service_page.merge_pdf.ctaTitle'),
    meta: {
      title: t('service_page.merge_pdf.meta.title'),
      description: t('service_page.merge_pdf.meta.description'),
    },
  };
};
