import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { getGoogleAuthRedirectPath } from 'helpers/getGoogleAuthRedirectPath';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useGoogleAuthButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectGmailAccount = () => {
    window.onbeforeunload = null;
    const url = buildOauthRequestUrl();

    const width = 700;
    const height = 600;

    if (window.innerWidth <= width) window.location.href = url;
    else {
      const left = (window.innerWidth - width) / 2 + window.screenX;
      const top = (window.innerHeight - height) / 2 + window.screenY;

      const popup = window.open(
        url,
        'OAuth2',
        `width=${width},height=${height},left=${left},top=${top},toolbar=0,scrollbars=0,status=0`
      );

      if (popup?.focus) popup.focus();

      const popupCheckInterval = setInterval(() => {
        try {
          if (!popup || popup.closed) {
            clearInterval(popupCheckInterval);
            return;
          }
          const popupURL = popup.location.href;
          if (!popupURL.startsWith(getGoogleAuthRedirectPath())) {
            return;
          }
          const url = new URL(popupURL);

          popup.close();
          clearInterval(popupCheckInterval);
          navigate(url.pathname + url.hash);
          window.focus();
          dispatch(toggleModal({ visible: false }));
        } catch (e) {
          // Ignore cross-origin errors until redirect occurs
        }
      }, 500);
    }

    dispatch(sendAnalyticEvent({ event: 'continue_google_tap' }));
  };

  return { connectGmailAccount };
};
