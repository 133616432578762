import { getGoogleAuthRedirectPath } from './getGoogleAuthRedirectPath';

export const PREVIOUS_PAGE_PARAM_NAME = 'googleAuthRedirectUrl';

const buildOauthRequestUrl = (): string => {
  const baseUrl = 'https://accounts.google.com/o/oauth2/auth';
  const params = new URLSearchParams();
  const previousPage = window.location.pathname + window.location.search;
  const redirectPath = getGoogleAuthRedirectPath();

  localStorage.setItem(PREVIOUS_PAGE_PARAM_NAME, previousPage);

  params.append('client_id', process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string);
  params.append('redirect_uri', redirectPath);
  params.append('response_type', 'id_token');
  params.append('response_mode', 'query');
  params.append('scope', 'openid profile email');
  params.append('fetch_basic_profile', 'true');
  params.append('gsiwebsdk', '2');

  return `${baseUrl}?${params.toString()}`;
};
export default buildOauthRequestUrl;
