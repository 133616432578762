import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

import {
  getInitialJapanesePlans,
  getInitialJapanesePlansAI,
  getInitialLatAmPlans,
  getInitialLatAmPlansAI,
  getInitialPolishTurkeyPlans,
  getInitialPolishTurkeyPlansAI,
  initialAIPlansRecurrentPrice,
  initialPlans,
  initialPlansRecurrentPrice,
} from 'helpers/generatePlanData';
import { getCurrencySymbol } from 'helpers/getCurrency';
import { countMonthlyPriceForAnnual } from 'utils/countMonthlyPriceForAnnual';

const initialState: ISubscription = {
  subscription: null,
  selectedPlan: null,
  plans: initialPlans,
  plansRecurrent: initialPlansRecurrentPrice,
  plansRecurrentAI: initialAIPlansRecurrentPrice,
  plansLatAm: getInitialLatAmPlans(),
  plansLatAmAI: getInitialLatAmPlansAI(),
  plansJapanese: getInitialJapanesePlans(),
  plansJapaneseAI: getInitialJapanesePlansAI(),
  plansPolishTurkey: getInitialPolishTurkeyPlans(),
  plansPolishTurkeyAI: getInitialPolishTurkeyPlansAI(),
  offerPlan: null,
  offerPlanTestB: null,
  order: null,
};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription | {} => {
  switch (action.type) {
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.SET_SELECTED_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.data.plan,
      };
    }

    case EReduxTypes.SET_ORDER_DATA: {
      return {
        ...state,
        order: action.data,
      };
    }

    case EReduxTypes.GET_PLANS: {
      const newPlans = [...state?.plans];

      const newPlansRecurrent = [...state?.plansRecurrent];
      const newPlansRecurrentAI = [...state?.plansRecurrentAI];

      const newPlansLatAm = [...state?.plansLatAm];
      const newPlansLatAmAI = [...state?.plansLatAmAI];

      const newPlansJapanese = [...state?.plansJapanese];
      const newPlansJapaneseAI = [...state?.plansJapaneseAI];

      const newPlansPolishTurkey = [...state?.plansPolishTurkey];
      const newPlansPolishTurkeyAI = [...state?.plansPolishTurkeyAI];

      const productsList = (action?.payload || [])?.filter(
        (item: any) => item?.name?.includes('the_best_pdf') || item?.name?.includes('TheBestPDF')
      );

      for (let i = 0; i < state?.plans?.length; i++) {
        const product = state?.plans[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansRecurrent?.length; i++) {
        const product = state?.plansRecurrent[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansRecurrentAI?.length; i++) {
        const product = state?.plansRecurrentAI[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansLatAm?.length; i++) {
        const product = state?.plansLatAm[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;

          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansLatAmAI?.length; i++) {
        const product = state?.plansLatAmAI[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;

          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansJapanese?.length; i++) {
        const product = state?.plansJapanese[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      for (let i = 0; i < state?.plansJapaneseAI?.length; i++) {
        const product = state?.plansJapaneseAI[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      for (let i = 0; i < state?.plansPolishTurkey?.length; i++) {
        const product = state?.plansPolishTurkey[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }

          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      for (let i = 0; i < state?.plansPolishTurkeyAI?.length; i++) {
        const product = state?.plansPolishTurkeyAI[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }

          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      return {
        ...state,
        plans: newPlans,
        plansRecurrent: newPlansRecurrent,
        plansRecurrentAI: newPlansRecurrentAI,
        plansLatAm: newPlansLatAm,
        plansLatAmAI: newPlansLatAmAI,
        plansJapanese: newPlansJapanese,
        plansJapaneseAI: newPlansJapaneseAI,
        plansPolishTurkey: newPlansPolishTurkey,
        plansPolishTurkeyAI: newPlansPolishTurkeyAI,
        offerPlan:
          (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_49_95_70%_discount_14_99'
          ) || null,
        offerPlanTestB:
          (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_39_95_70%_discount'
          ) || null,
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;
