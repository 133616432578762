import {
  aiLatAmPlansSelector,
  aiPlansJapaneseSelector,
  aiPlansPolishTurkeySelector,
  aiPlansRecurrentSelector,
  latAmPlansSelector,
  plansJapaneseSelector,
  plansPolishTurkeySelector,
  plansRecurrentSelector,
} from 'data/selectors/subscriptions';
import {
  isJapanUserSelector,
  isLatAmUserSelector,
  isPolishTurkeyUserSelector,
} from 'data/selectors/user';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { IPlan, IPlanAI } from 'ts/interfaces/plan/plan';
import { useSalesWithAI } from './growthBook/useSalesWithAI';

export const usePlans = (): IPlan[] | IPlanAI[] => {
  const isJapanUser = useSelector(isJapanUserSelector);
  const isLatAmUser = useSelector(isLatAmUserSelector);
  const isPolishTurkeyUser = useSelector(isPolishTurkeyUserSelector);

  const defaultPlans = useSelector(plansRecurrentSelector);
  const defaultPlansAI = useSelector(aiPlansRecurrentSelector);

  const latAmPlans = useSelector(latAmPlansSelector);
  const latAmPlansAI = useSelector(aiLatAmPlansSelector);

  const japanesePlans = useSelector(plansJapaneseSelector);
  const japanesePlansAI = useSelector(aiPlansJapaneseSelector);

  const polishTurkeyPlans = useSelector(plansPolishTurkeySelector);
  const polishTurkeyPlansAI = useSelector(aiPlansPolishTurkeySelector);

  const salesScreenWithAI = useSalesWithAI();

  const defaultPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: defaultPlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: defaultPlansAI,
    }),
    [defaultPlans, defaultPlansAI]
  );

  const latAmPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: latAmPlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: latAmPlansAI,
    }),
    [latAmPlans, latAmPlansAI]
  );

  const japanesePlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: japanesePlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: japanesePlansAI,
    }),
    [japanesePlans, japanesePlansAI]
  );

  const polishTurkeyPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: polishTurkeyPlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: polishTurkeyPlansAI,
    }),
    [polishTurkeyPlans, polishTurkeyPlansAI]
  );

  return useMemo(() => {
    if (isJapanUser) return japanesePlansMap[salesScreenWithAI];

    if (isLatAmUser) return latAmPlansMap[salesScreenWithAI];

    if (isPolishTurkeyUser) return polishTurkeyPlansMap[salesScreenWithAI];

    return defaultPlansMap[salesScreenWithAI];
  }, [
    isJapanUser,
    isLatAmUser,
    isPolishTurkeyUser,
    japanesePlansMap,
    salesScreenWithAI,
    latAmPlansMap,
    polishTurkeyPlansMap,
    defaultPlansMap,
  ]);
};
